@import "assets/bootstrap.min.css";

@import "@ag-grid-community/styles/ag-grid.min.css";
@import "@ag-grid-community/styles/ag-theme-balham.css";

@import url('dp_scheduler.css');
@import url('dp_calendar.css');

@import "quill/dist/quill.snow.css";
@import "@ctrl/ngx-emoji-mart/picker";
@import "perfect-scrollbar/css/perfect-scrollbar.css";

* {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

html, body {
	margin: 0;
}

input {
	-webkit-touch-callout: unset !important;
	-webkit-user-select: text !important;
	-moz-user-select: text !important;
	-ms-user-select: text !important;
	user-select: text !important;
}

.full-width {
	width: 100%;
}

.full-height {
	height: 100%;
}

.form-container {
	display: flex;
	flex-direction: column;
	padding-bottom: 50px;
}

.fullscreen-container {
	height: calc(100vh - 96px);
	display: flex;
	flex-direction: column;
}

.touch-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 24px;
	bottom: 0;
}

.touch-container-xs {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.absolute-center {
	position: absolute;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	overflow: hidden;
}

.label {
	padding-top: 1em;
	white-space: nowrap;
}

.hidden {
	display: none !important;
}

.dimmed {
	color: #b0b0b0 !important;
}

mark {
	background-color: yellow;
	padding: 0;
}

// deprecated ?
.caption-1 {
	font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
	font-size: 24px;
	line-height: 32px;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	letter-spacing: normal;
	margin: 0;
}

/* Material Elements */
.mat-mdc-form-field.full-width {
	margin-bottom: 10px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
	background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
	background-color: #cccccc !important;
}

.mat-mdc-outlined-button {
	border: 1px solid #e0e0e0 !important;
}

.mat-mdc-footer-row, .mat-mdc-header-row, .mat-mdc-row, td.mat-mdc-cell, td.mat-mdc-footer-cell, th.mat-mdc-header-cell {
	border-color: #e0e0e0 !important;
}

.mat-slide-toggle-bar {
	background-color: #9e9e9e;
}


.mat-mdc-dialog-title {
	background: #e9e9e9;
	cursor: move;
}

.mdc-button .mdc-button__label {
	letter-spacing: normal;
}

.mat-mdc-card-title {
	padding: .5em 1em 0 1em;
}

.mdc-card-xs {
	padding: 1em;
}

.mdc-card-xs {
	border: none !important;
	box-shadow: none !important;
	padding: 0 !important;
}

th.mat-mdc-header-cell {
	font-size: 1em;
}

.mat-sort-header-button {
	outline: none !important;
}

.mdc-tooltip__surface {
	white-space: pre-line;
	text-align: left!important;
}

/* foCloud Styles */
.receipt-display {
	font-family: 'Lucida Console', Monaco, monospace !important;
	font-size: 11px;
	white-space: pre-wrap;
}

.snackbar-danger {
	background-color: #ef9a9a !important;
	color: black !important;
	// Angular 15 bug
	--mdc-snackbar-container-color: #ef9a9a !important;
	--mdc-snackbar-supporting-text-color: black !important;

	.mdc-snackbar__surface {
		width: 668px !important;
		max-width: .9vw;
	}
}

.snackbar-success {
	background-color: #a5d6a7 !important;
	color: black !important;
	// Angular 15 bug
	--mdc-snackbar-container-color: #a5d6a7 !important;
	--mdc-snackbar-supporting-text-color: black !important;
}

.snackbar-success button {
	color: #333;
	font-weight: bold;
}

.snackbar-warning {
	background-color: #fcf0c0 !important;
	color: black !important;
	// Angular 15 bug
	--mdc-snackbar-container-color: #fcf0c0 !important;
	--mdc-snackbar-supporting-text-color: black !important;
}

.snackbar-warning button {
	color: #333;
	font-weight: bold;
}

.select-panel-80 {
	max-height: 80vh !important;
}

.select-panel-50 {
	max-height: 50vh !important;
}

.select-all {
	-webkit-touch-callout: unset !important;
	-webkit-user-select: all !important;
	-moz-user-select: all !important;
	-ms-user-select: all !important;
	user-select: all !important;
}

.select-text {
	-webkit-touch-callout: unset !important;
	-webkit-user-select: text !important;
	-moz-user-select: text !important;
	-ms-user-select: text !important;
	user-select: text !important;
}

.mat-mdc-icon-button {
	width: 40px !important;
	height: 40px !important;
	padding: 0px !important;
	display: inline-flex !important;
	align-items: center;
	justify-content: center;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
	width: 40px !important;
	height: 40px !important;
}

.mat-mdc-icon-button .mat-mdc-focus-indicator {
	width: 40px !important;
	height: 40px !important;
}

.small-icon-button {
	width: 30px !important;
	height: 30px !important;
	padding: 0px !important;
	display: inline-flex !important;
	align-items: center;
	justify-content: center;
}

.small-icon-button .mat-mdc-button-touch-target {
	width: 30px !important;
	height: 30px !important;
}

.icon12 {
	font-size: 12px !important;
	height: 12px !important;
	width: 12px !important;
	vertical-align: middle !important;
}

.icon16 {
	font-size: 16px !important;
	height: 16px !important;
	width: 16px !important;
	vertical-align: middle;
}

.icon24 {
	font-size: 24px !important;
	height: 24px !important;
	width: 24px !important;
	vertical-align: middle;
}

.flipX {
	-o-transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-ms-transform: scaleX(-1);
	transform: scaleX(-1);
}

.flipY {
	-o-transform: scaleY(-1);
	-moz-transform: scaleY(-1);
	-webkit-transform: scaleY(-1);
	-ms-transform: scaleY(-1);
	transform: scaleY(-1);
}

.avatar30 {
	vertical-align: middle;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.avatar100 {
	vertical-align: middle;
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.mini-chip {
	display: inline-block !important;
}

	.mini-chip .mat-mdc-chip {
		font-size: 11px;
		min-height: 18px;
		height: 18px;
		margin: 2px !important;
	}

.drop-zone-active {
	background-color: #fcf0c0;
	border: 4px dashed #606060;
}

.list:hover {
	background-color: #eff0f1;
	cursor: pointer;
}

.list-selected {
	background-color: aliceblue !important;
}

.list-selected:hover {
	background-color: #d8dfe5 !important;
}

.toolbar {
	padding: 0 !important;
	background-color: transparent !important;
	margin-bottom: 1em !important;
	height: 56px !important;
}

div.click-effect {
	position: fixed;
	box-sizing: border-box;
	border-style: solid;
	border-color: #000;
	border-radius: 50%;
	animation: click-effect 0.4s ease-out;
	z-index: 99999;
}

@keyframes click-effect {
	0% {
		opacity: 1;
		width: 15em;
		height: 15em;
		margin: -7.5em;
		border-width: 0.5em;
	}

	100% {
		opacity: 0.2;
		width: 0.5em;
		height: 0.5em;
		margin: -0.25em;
		border-width: 0.03em;
	}
}
