.fc_main {
	border: 1px solid #c0c0c0;
}

	.fc_main, .fc_main td {
		font-family: Tahoma, Arial, Helvetica, sans-serif;
		font-size: 12px;
	}

.fc_event {
	color: #333333;
}

.fc_event_inner {
	position: absolute;
	overflow: hidden;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	margin: 0px;
	background-color: #ffffff;
	padding: 2px;
	padding-left: 6px;
	border: 1px solid #cccccc;
}

.fc_alldayevent {
}

.fc_alldayevent_inner {
	position: absolute;
	overflow: hidden;
	left: 1px;
	right: 0px;
	top: 1px;
	bottom: 0px;
	margin: 0px;
	color: #333333;
	background-color: #ffffff;

	padding: 2px;
	border: 1px solid #cccccc;
	text-align: left;
}

.fc_alldayheader_inner {
	text-align: center;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #c0c0c0;
	border-bottom: 1px solid #c0c0c0;
	color: #333333;
	background: #f3f3f3;
}

.fc_colheader_inner {
	text-align: center;
	padding: 2px;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #c0c0c0;
	border-bottom: 1px solid #c0c0c0;
	color: #333333;
	background: #f3f3f3;
}

.fc_rowheader_inner {
	font-size: 16pt;
	text-align: right;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #c0c0c0;
	border-bottom: 1px solid #c0c0c0;
	color: #333333;
	background: #f3f3f3;
}

.fc_rowheader_minutes {
	font-size: 10px;
	vertical-align: super;
	padding-left: 2px;
	padding-right: 2px;
}

.fc_corner_inner {
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #c0c0c0;
	border-bottom: 1px solid #c0c0c0;
	color: #333333;
	background: #f3f3f3;
}

.fc_cornerright_inner {
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #c0c0c0;
	border-bottom: 1px solid #c0c0c0;
	color: #333333;
	background: #f3f3f3;
}

.fc_rowheader_inner {
	padding: 2px;
}

.fc_cell_inner {
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #eeeeee;
	border-bottom: 1px solid #eeeeee;
	background: #f9f9f9;
}

.fc_cell_business .fc_cell_inner {
	background: #ffffff;
}

.fc_message {
	padding: 10px;
	opacity: 0.9;
	color: #ffffff;
	background: #ffa216;
}

.fc_shadow_inner {
	background-color: #666666;
	opacity: 0.5;
	height: 100%;
}

.fc_event_bar {
	top: 0px;
	left: 0px;
	bottom: 0px;
	width: 4px;
	background-color: #9dc8e8;
}

.fc_event_bar_inner {
	position: absolute;
	width: 4px;
	background-color: #1066a8;
}

.fc_event_delete {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAI5JREFUKFNtkLERgCAMRbmzdK8s4gAUlhYOYEHJEJYOYOEwDmGBPxC4kOPfvePy84MGR0RJ2N1A8H3N6DATwSQ57m2ql8NBG+AEM7D+UW+wjdfUPgerYNgB5gOLRHqhcasg84C2QxPMtrUhSqQIhg7ypy9VM2EUZPI/4rQ7rGxqo9sadTegw+UdjeDLAKUfhbaQUVPIfJYAAAAASUVORK5CYII=) center center no-repeat;
	opacity: 0.6;
	cursor: pointer;
}

	.fc_event_delete:hover {
		opacity: 1;
	}

.fc_scroll_up {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAB3RJTUUH2wESDiYcrhwCiQAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAARnQU1BAACxjwv8YQUAAACcSURBVHjaY2AgF9wWsTW6yGMlhi7OhC7AyMDQzMnBXIpFHAFuCtuaMTP+P8nA8P/b1x//FfW/HHuF1UQmxv+NUP1c3OxMVVhNvCVi683E8H8LXOY/w9+fTH81tF8fv4NiIpBRj+YoZtZ/LDUoJmKYhsVUpv0MDiyMDP96sIYV0FS2/8z9ICaLlOhvS4b/jC//MzC8xBG0vJeF7GQBlK0xdiUzCtsAAAAASUVORK5CYII=);
}

.fc_scroll_down {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAALiMAAC4jAXilP3YAAACqSURBVChTY7wpam3L9J+xmQEP+PGPKZZxP4MDi4zI78uMDIwa2NT+Z2DYovrmiC+TI8OBP/8ZmEqwGvif4e8vxr+FIDkmEKH25vBWBgbG0+iK/zEwLtF+ffwOXCGI8Y+BoRFFIdC030x/WmBiYBNhpgLdswNJ8RSYaSgmgk39z1gPUfj/29ef/9rwhQTDHRHbrbdEbLvRFcGthkkAra/9/uMvhkK8piNLAgCRpTnNn4AEmAAAAABJRU5ErkJggg==);
}

.fc_now {
	background-color: red;
}



.fc_shadow_forbidden .fc_shadow_inner {
	background-color: red;
}

.fc_shadow_top {
	box-sizing: border-box;
	padding: 2px;
	border: 1px solid #ccc;
	background: #fff;
	background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}

.fc_shadow_bottom {
	box-sizing: border-box;
	padding: 2px;
	border: 1px solid #ccc;
	background: #fff;
	background: linear-gradient(to bottom #ffffff 0%, #eeeeee);
}

.fc_crosshair_vertical, .fc_crosshair_horizontal, .fc_crosshair_left, .fc_crosshair_top {
	background-color: gray;
	opacity: 0.2;
}

.fc_loading {
	background-color: orange;
	color: white;
	padding: 2px;
}

.fc_scroll {
	background-color: #f3f3f3;
}
