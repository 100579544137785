.menu_default_main {
	user-select: none;
}

.fo_main 
{
    border: 1px solid #c0c0c0;
}
.fo_main, .fo_main td 
{
    font-family: inherit;
    font-size: inherit;
}
.fo_event { 
    border-radius: 4px;
}
.fo_event { 
    color: #333333;
}
.fo_event_inner { 
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: 0px;
    overflow: hidden;
    background-color: #ffffff;
    background: linear-gradient(to bottom, #ffffff 0%, #f5f5f5);
    border-radius: 4px;
    padding: 2px;
    border: 1px solid #cccccc;
    display: flex;
    align-items: center;
}


.fo_timeheadergroup,
.fo_timeheadercol {
	color: #333333;
	background: #f5f5f5;
}
.fo_rowheader,
.fo_corner {
	color: #333333;
	background: #f5f5f5;
}

.fo_rowheader_inner {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	padding: 2px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.fo_timeheadergroup,
.fo_timeheadercol
{
}
.fo_timeheadergroup_inner,
.fo_timeheadercol_inner
{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-right: 1px solid #c0c0c0;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fo_timeheadergroup_inner {
    border-bottom: 1px solid #c0c0c0;
}
.fo_divider,
.fo_splitter,
.fo_divider_horizontal,
.fo_resourcedivider 
{
    background-color: #c0c0c0;
}
.fo_matrix_vertical_line,
.fo_matrix_horizontal_line 
{
    background-color: #eeeeee;
}
.fo_cell 
{
    background: #f9f9f9;
}
.fo_cell.fo_cell_business 
{
    background: #ffffff;
}


.fo_message 
{
    padding: 10px;
    opacity: 0.9;
    color: #ffffff;
    background: #ff9317;
}
.fo_shadow_inner 
{
    background-color: #666666;
    opacity: 0.5;
    height: 100%;
    border-radius: 4px;
}
.fo_event_bar
{
    top: 0px;
    left: 0px;
    right: 0px;
    height: 3px;
    background-color: #9dc8e8;
    border-bottom: 1px solid #999999;
}
.fo_event_bar_inner  
{
    position: absolute;
    height: 3px;
    background-color: #1066a8;
}
.fo_matrix_vertical_break 
{
    background-color: #000;
}
.fo_cellparent {
}
.fo_tree_image_no_children {
/*
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAIAAABv85FHAAAAKXRFWHRDcmVhdGlvbiBUaW1lAHDhIDMwIEkgMjAwOSAwODo0NjozMSArMDEwMClDkt4AAAAHdElNRQfZAR4HLzEyzsCJAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAABGdBTUEAALGPC/xhBQAAADBJREFUeNpjrK6s5uTl/P75OybJ0NLW8h8bAIozgeSxAaA4E1A7VjmgOL31MeLxHwCeXUT0WkFMKAAAAABJRU5ErkJggg==);
*/
}
.fo_tree_image_expand {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAIAAABv85FHAAAAKXRFWHRDcmVhdGlvbiBUaW1lAHDhIDMwIEkgMjAwOSAwODo0NjozMSArMDEwMClDkt4AAAAHdElNRQfZAR4HLyUoFBT0AAAACXBIWXMAAA7CAAAOwgEVKEqAAAAABGdBTUEAALGPC/xhBQAAAFJJREFUeNpjrK6s5uTl/P75OybJ0NLW8h8bAIozgeRhgJGREc4GijMBtTNgA0BxFog+uA4IA2gmUJwFog/IgUhAGBB9KPYhA3T74Jog+hjx+A8A1KRQ+AN5vcwAAAAASUVORK5CYII=);
}
.fo_tree_image_collapse {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAIAAABv85FHAAAAKXRFWHRDcmVhdGlvbiBUaW1lAHDhIDMwIEkgMjAwOSAwODo0NjozMSArMDEwMClDkt4AAAAHdElNRQfZAR4HLxB+p9DXAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAABGdBTUEAALGPC/xhBQAAAENJREFUeNpjrK6s5uTl/P75OybJ0NLW8h8bAIozgeSxAaA4E1A7VjmgOAtEHyMjI7IE0EygOAtEH5CDqY9c+xjx+A8ANndK9WaZlP4AAAAASUVORK5CYII=);
}
.fo_columnheader 
{
}
.fo_columnheader_inner {
    font-weight: bold;
}
.fo_columnheader_cell {
	background: #f5f5f5;
}
.fo_columnheader_splitter {
    background-color: #666;
    opacity: 0.5;
}
.fo_columnheader_cell_inner {
    padding: 2px;   
}
.fo_timeheader_float {
    display: flex;
    align-items: center;
    justify-content: center;
}
.fo_timeheader_float_inner {
    padding: 2px;
}
.fo_event_float {
    display: flex;
    align-items: center;
}
.fo_event_float_inner {
    padding: 6px 2px 2px 7px;
    padding-left: 9px;
    /*top: -2px;*/
    position: relative;    
}
.fo_event_float_inner:after {
    content:"";
    border-color: transparent #333333 transparent transparent;
    border-style:solid;
    border-width:5px;
    width:0;
    height:0;
    position:absolute;
    top:8px;
    left:-4px;
}
.fo_event_move_left {
    box-sizing: border-box; 
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}
.fo_event_move_right {
    box-sizing: border-box; 
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
}
.fo_event_delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAI5JREFUKFNtkLERgCAMRbmzdK8s4gAUlhYOYEHJEJYOYOEwDmGBPxC4kOPfvePy84MGR0RJ2N1A8H3N6DATwSQ57m2ql8NBG+AEM7D+UW+wjdfUPgerYNgB5gOLRHqhcasg84C2QxPMtrUhSqQIhg7ypy9VM2EUZPI/4rQ7rGxqo9sadTegw+UdjeDLAKUfhbaQUVPIfJYAAAAASUVORK5CYII=) center center no-repeat; opacity: 0.6; -ms-filter:'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
    cursor: pointer;
    opacity: 0.6;
}
.fo_event_delete:hover {
    opacity: 1;
}
.fo_rowmove_handle { background-repeat: no-repeat; background-position: center center; background-color: #ccc; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAKCAYAAACT+/8OAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAClJREFUGFdj+P//P4O9vX2Bg4NDP4gNFgBytgPxebgAMsYuQGMz/jMAAFsTZDPYJlDHAAAAAElFTkSuQmCC); cursor: move; }
.fo_rowmove_source { background-color: black; opacity: 0.2; }
.fo_rowmove_position_before, .fo_rowmove_position_after { background-color: #999; height: 2px; }
.fo_rowmove_position_child { margin-left: 10px; background-color: #999; height: 2px; }
.fo_rowmove_position_child:before { content: '+'; color: #999; position: absolute; top: -8px; left: -10px; }
.fo_rowmove_position_forbidden { background-color: red; height: 2px; margin-left: 10px; }
.fo_rowmove_position_forbidden:before { content: 'x'; color: red; position: absolute; top: -8px; left: -10px; }
.fo_link_horizontal { border-bottom-style: solid; border-bottom-color: red }
.fo_link_vertical { border-right-style: solid; border-right-color: red }
.fo_link_arrow_right:before { content: ''; border-width: 6px; border-color: transparent transparent transparent red; border-style: solid; width: 0px; height:0px; position: absolute; }
.fo_link_arrow_left:before { content: ''; border-width: 6px; border-color: transparent red transparent transparent; border-style: solid; width: 0px; height:0px; position: absolute; }
.fo_link_arrow_down:before { content: ''; border-width: 6px; border-color: red transparent transparent transparent; border-style: solid; width: 0px; height:0px; position: absolute; }
.fo_link_arrow_up:before { content: ''; border-width: 6px; border-color: transparent transparent red transparent; border-style: solid; width: 0px; height:0px; position: absolute; }
.fo_shadow_overlap .fo_shadow_inner { background-color: red; }
.fo_overlay { background-color: gray; opacity: 0.5; }
.fo_event_group { box-sizing: border-box; font-size:10px; color:#666; padding:2px 2px 2px 2px; overflow:hidden; border:1px solid #ccc; background-color: #fff; }
.fo_header_icon {
	box-sizing: border-box;
	border: 1px solid #c0c0c0;
	background-color: #f5f5f5;
	color: #333333;
}
.fo_header_icon:hover { background-color: #f5f5f5; }
.fo_header_icon_hide:before { content: '\00AB'; }
.fo_header_icon_show:before { content: '\00BB'; }
.fo_rowheader.fo_rowheader_selected { 
    background-color: #aaa;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
    background-size: 20px 20px; 
}
.fo_row_new .fo_rowheader_inner { cursor: text; background-position: 0px 5px; background-repeat: no-repeat; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABUSURBVChTY0ACslAaK2CC0iCQDMSlECYmQFYIAl1AjFUxukIQwKoYm0IQwFCMSyEIaEJpMMClcD4Qp0CYEIBNIUzRPzAPCtAVYlWEDgyAGIdTGBgAbqEJYyjqa3oAAAAASUVORK5CYII=); }
.fo_row_new .fo_rowheader_inner:hover { background: white; }
.fo_rowheader textarea { padding: 3px; }
.fo_rowheader_scroll { cursor: default; }
.fo_shadow_forbidden .fo_shadow_inner { background-color: red; }
.fo_event_moving_source { opacity: 0.5; }
.fo_linkpoint { background-color: white; border: 1px solid gray; border-radius: 5px; }
.fo_linkpoint.fo_linkpoint_hover { background-color: black; }
.fo_event.fo_event_version .fo_event_inner { 
    background-color: #cfdde8;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
    background-size: 20px 20px; 
}
.fo_crosshair_vertical, .fo_crosshair_horizontal, .fo_crosshair_left, .fo_crosshair_top { background-color: gray; opacity: 0.2; }
.fo_link_dot { border-radius: 10px; background-color: red; }
.fo_task_milestone .fo_event_inner { position:absolute;top:16%;left:16%;right:16%;bottom:16%; background: #38761d; border: 0px none; transform: rotate(45deg); filter: none; }
.fo_event_left { white-space: nowrap; padding-top: 5px; color: #666; cursor: default; }
.fo_event_right { white-space: nowrap; padding-top: 5px; color: #666; cursor: default; }

